<template lang="pug">
  .table-layout
    table.table.table--cell-overflow-hidden.table--cell-overflow-visible
      thead.table__header
        tr.table__row.table__row--header
          th.table__cell {{ 'base.name' | translate }}
          th.table__cell {{ 'base.capacity' | translate }}
          //- th.table__cell {{ 'pages.settings.mixing_time' | translate }}
          th.table__cell {{ 'pages.settings.calibration' | translate }}
      v-table-body(
        :cols="3"
        :loading="loading"
        :empty="!items.length")
        mixer-table-row(
          v-for="mixer in items"
          :key="mixer.id"
          :mixer="mixer")
      tfoot.table__footer(
        v-if="!loading && showPagination")
        tr.table__row.table__row--footer
          td.table__cell(colspan="4")
            .table__pagination
              v-paginate(
                v-model="page"
                :page-count="pageCount"
                :click-handler="pageChangeHandler")

</template>

<script>
  import { mapActions } from 'vuex'

  import paginationMixin from '@/mixins/pagination.mixin'
  import MixerTableRow from './MixerTableRow'

  export default {
    name: 'MixerTable',

    mixins: [paginationMixin],

    components: {
      MixerTableRow
    },

    mounted () {
      this.setupPagination(this.fetchMixers)
    },

    methods: {
      ...mapActions(['fetchMixers'])
    }
  }
</script>

<style lang="scss" scoped>
</style>
