<template lang="pug">
  tr.table__row.table__row--body
    td.table__cell {{ mixer.name }}
    td.table__cell {{ mixer.min_weight }} - {{ mixer.max_weight }}
    td.table__cell {{ calibrationDate }}
    td.table__cell.table__cell--medium
      .table__cell-wrapper
          v-btn.table__button.table__button--settings-auto(
            icon
            v-permission-hide="permissions.update_mixer"
            @click="modals.showSettings = true")
            icon-gear.table__settings

          v-dialog(
            max-width="348"
            v-model="modals.showSettings")
            settings-modal(
              :item="mixer"
              @close="modals.showSettings = false")
</template>

<script>
  import { mapActions } from 'vuex'

  import IconGear from '@/components/icons/IconGear'
  import SettingsModal from './modals/SettingsModal'
  import permissions from '@/util/permissions'

  export default {
    name: 'MixerTableRow',

    props: {
      mixer: {
        type: Object,
        required: true
      }
    },

    components: {
      IconGear,
      SettingsModal
    },

    data: () => ({
      form: {
        calibration_id: null
      },
      modals: {
        showSettings: false
      },
      permissions: permissions
    }),

    computed: {

      calibrationDate () {
        return this.mixer.calibration_at
      }
    },

    methods: {
      ...mapActions(['updateMixer'])
    }
  }
</script>

<style lang="scss" scoped>
</style>
