<template lang="pug">
  v-card
    .settings-modal
      v-card-title
        h2.card-title {{ 'modals.mixer.settings.title' | translate }}
      v-card-content
        v-text-field.mb-3(
          :label="$t('base.name')"
          v-model="form.name")
        .cols-x
          .col-x-2.mb-3
            v-text-field(
              :label="$t('pages.settings.capacity_from')"
              v-model="form.minWeight")
          .col-x-2.mb-3
            v-text-field(
              :label="$t('pages.settings.capacity_to')"
              v-model="form.maxWeight")

        //- v-select.mb-3(
        //-   :label="$t('modals.mixer.settings.auto_switch')"
        //-   item-name="label"
        //-   item-value="value"
        //-   :options="options"
        //-   v-model="form.auto_load")

        //- v-switch.mb-3(
        //-   :label="$t('modals.mixer.settings.weight_up')"
        //-   v-model="form.weight_up")

        //- v-switch(
        //-   :label="$t('modals.mixer.settings.rounding')"
        //-   v-model="form.rounding")

      v-card-actions
        v-btn(
          :disabled="!isNameValid || !isMaxWeightValid || !isMinWeightValid"
          :loading="loading"
          @click="onUpdate").mr-2 {{ 'actions.save' | translate }}
        delete-btn(
          v-permission-hide="permissions.delete_mixer"
          @delete="onDelete")

</template>

<script>
import DeleteBtn from '@/components/common/delete-btn/DeleteBtn'
import permissions from '@/util/permissions'

export default {
  name: 'SettingsModal',

  components: {
    DeleteBtn
  },

  props: {
    item: Object
  },

  data: () => ({
    form: {
      name: '',
      minWeight: '',
      maxWeight: '',
      weight_up: false,
      rounding: false,
      auto_load: null
    },
    options: [
      { id: 0, label: 'base.turned_off', value: 0 },
      { id: 1, label: '3 сек', value: 3 },
      { id: 2, label: '5 сек', value: 5 },
      { id: 3, label: '7 сек', value: 7 },
      { id: 4, label: '10 сек', value: 10 }
    ],
    loading: false,
    permissions: permissions
  }),

  computed: {
    isNameValid () {
      return !!this.form.name
    },

    isMaxWeightValid () {
      return this.form.maxWeight === 0
        ? !this.form.maxWeight
        : !!this.form.maxWeight
    },

    isMinWeightValid () {
      return this.form.minWeight === 0
        ? !this.form.minWeight
        : !!this.form.minWeight
    }
  },

  mounted () {
    this.form.name = this.item.name
    this.form.minWeight = this.item.min_weight
    this.form.maxWeight = this.item.max_weight
    this.form.weight_up = this.item.weight_up
    this.form.rounding = this.item.rounding
    this.form.auto_load = this.item.auto_load
  },

  methods: {
    async onUpdate () {
      this.loading = true
      const payload = {
        name: this.form.name,
        min_weight: +this.form.minWeight,
        max_weight: +this.form.maxWeight,
        weight_up: !!this.form.weight_up,
        rounding: !!this.form.rounding,
        auto_load: +this.form.auto_load
      }
      const { data, error } = await this.$store.dispatch('updateMixer', { id: this.item.id, payload })
      this.loading = false
      if (error) {
        for (const key in data) {
          for (const msg of data[key]) {
            this.$toasted.show(this.$t(`errors.${msg}`), {
              position: 'bottom-center',
              duration: 5000
            })
          }
        }
      } else {
        this.$emit('close')
      }
    },

    async onDelete () {
      await this.$store.dispatch('deleteMixer', { id: this.item.id })
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
